import { Flex, Heading } from '@chakra-ui/react'
import { Headerbar, BaseLayout, HtmlHead } from '../c'

export default function Custom404() {
  return (
    <>
      <HtmlHead title='Farva - 404' />
      <BaseLayout>
        <Headerbar />
        <Flex direction='column' minW='100%' grow='1' bg='white'>
          <Flex
            maxWidth='900px'
            w='90%'
            direction='column'
            alignItems='center'
            alignSelf='center'
            justifyContent='center'
            borderRadius='2xl'
            minH='500px'
          >
            <Heading size='lg' mt={3} mb={3}>
              404 - not found
            </Heading>
          </Flex>
        </Flex>
      </BaseLayout>
    </>
  )
}
